;(function($) {
	personalization = {
		init: function() {},
		accounts: function() {
			$.get("/personalization/accounts", function(data) {
				$(".js-outages-main-nav-dropdown").html(data["outages-navigation"]);

				if (data["signed-in"]) {
					$(".js-nav-log-in").hide().addClass("hide");
					$(".js-nav-profile").show().css("display", "").removeClass("hide");
					$(".js-nav-log-out").show().css("display", "").removeClass("hide");
					$(".js-first-and-last-name").html(data["first-name"] + ' ' + data["last-name"]);
					$(".js-members-site-link").show().css("display", "").removeClass("hide");
				}
				else {
					$(".js-nav-log-in").show().css("display", "").removeClass("invisible");
					$(".js-nav-profile").hide().addClass("hide");
					$(".js-nav-log-out").hide().addClass("hide");
					$(".js-first-and-last-name").html("");
					$(".js-members-site-link").hide().addClass("hide");
					$(".mobile-site-links").hide();
				}
			});
		}
	};
})(jQuery);
